import React, { Component } from "react";
import ConfigAccountAddDecomForm from "./ConfigAccountAddDecomForm";

class ConfigAccountAddDecomFormContainer extends Component {
  render() {
    const field_available_options = [
      { key: "t", text: "Account Monitored", value: 'ExceptionsCheckEnabled' },
      { key: "f", text: "Action Enabled", value: 'ActionEnabled' }
    ];
    const forActionEnabled = [
      { key: "t", text: "True", value: "True" },
      { key: "f", text: "False", value: "False" }
    ]
    const forExceptionsCheckEnabled = [
      { key: "t", text: "True", value: true },
      { key: "f", text: "False", value: false }
    ]
    return (
      <ConfigAccountAddDecomForm
       authState={this.props.authState}
       field_available_options={field_available_options}
       forActionEnabled={forActionEnabled}
       forExceptionsCheckEnabled={forExceptionsCheckEnabled}
       {...this.props}
       />
    );
  }
}

export default ConfigAccountAddDecomFormContainer;