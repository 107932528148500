import React, { Component } from 'react';
import ConfigAccountSearchComp from './ConfigAccountSearchComp';
import axios from 'axios';
import SortableTable from "../../../../Components/ReUsableComponent/SortableTable";
import ConfigAccountEditFormContainer from './Edit/ConfigAccountEditFormContainer';
import ConfigAccountDeleteForm from './Delete/ConfigAccountDeleteForm';
import * as yup from "yup";
import config from '../../../../Config/Config';
import { commercialCloud } from '../../../../Config/appConstant';

class ConfigAccountSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleId: '',
      ruleSelected:'',
      accId:'',
      acc_options:[],
      optionlistdata:[],
      searchStatus:'',
      searchResultData:'',
      data:[],
      toEdit:'no',
      toDelete:'no',
      dataToEdit:'',
      dataToDelete:'',
      openModal:false,
      headers:['RuleId', 'RuleName','AccountId','ActionEnabled','AttributesKey'],
      toastMessage:'',
      onToastMsg:false,
      toastColour:'green',
      selectCloud:"",
      isSearching:false,
      rulesStore:[]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.readDataFromApi = this.readDataFromApi.bind(this);
    this.handleEditPopUpClose=this.handleEditPopUpClose.bind(this);
    this.handleDeleteConfirm=this.handleDeleteConfirm.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.handleSelectedCloudType = this.handleSelectedCloudType.bind(this)
  }
  schema = yup.object().shape({
    accId: yup.string()
    .required("Account ID is a required field")
    .matches(/^[0-9]+$/, "Account ID must be only digits")
    .min(12, "Account ID must be exactly 12 digits")
    .max(12, "Account ID must be exactly 12 digits")
  });
  handleDropDownChange=(event,data)=>{
    this.setState({[data.name]: data.value});
  }

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }

  handleChange = (data) => {
    this.setState({ [data.target.name]: data.target.value }, () =>
      this.schema
        .validate(this.state, { abortEarly: false })
        .then((valid) => this.setState({ errorPaths: [], errors: [] })) //called if the entire form is valid
        .catch((err) =>
          this.setState({
            errors: err.errors,
            errorPaths: err.inner.map((i) => i.path),
          })
        )
    ); //called if any field is invalid
  };

  dataEditedLoading=()=>{
    this.getDataForSearch();
  }

  handleSubmit=(event)=>{
    if (this.state.ruleId === '0000'){
      this.setState({
        onToastMsg:true,
        toastMessage:'Select a rule id and click on search',
        toastColour:'red'
      })
    }
    else {
      this.setState({['searchStatus']:''})
        this.getDataForSearch();
    }
  }


  readDataAccountIdFromApi=()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const options = {
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };
    axios.get(selectedUrl+'account/account-list',options)
      .then(res => {
        var acc_list_created = res.data.body.data;
        console.log(res.data.body.data)
        this.setState({['acc_options']:acc_list_created});
      })
      .catch(function (error) {
        console.log(error);
    })
  }

getDataForSearch=async()=>{
    this.setState((prevState)=>({...prevState,isSearching:true}))
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
    const data = {
      params: {RuleId:parseInt(this.state.ruleId),AccountId:(this.state.accId)},
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };
    await axios.get(selectedUrl+'account/search',data)
      .then(res => {
        var accountSearchResultData = res.data.body.data;
        var statusCode = res.data.status_code

        if (statusCode === 200){
          this.setState({
            onToastMsg:false,
            toastMessage:res.data.body.message,
            toastColour:'green',
            data: accountSearchResultData,
            searchStatus:statusCode,
            searchResultData:accountSearchResultData,
            isSearching:false
          })
          // this.handleClear();
        }
        else{
          this.setState({
            onToastMsg:true,
            toastMessage:res.data.body.message,
            toastColour:'red',
            isSearching:false
          })
        }
      })
      .catch(function (error) {
        console.log(error);
        this.setState((prevState)=>({...prevState,isSearching:false}))
    })
  }

  readDataFromApi=()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud? config.apiendpoint : config.govCloudApi
    const options = {
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    }

    axios.get(selectedUrl + 'configrules',options)
      .then(res => {
        var ruleid_list = res.data.body.data;
        let option_list=[];
        ruleid_list?.filter((item)=>(item?.CloudCompatibility !== undefined && item?.CloudCompatibility[this.state.selectCloud]) ).map(myFunction)

        function myFunction(element)
        {
          var new_element={};
          new_element['key'] =element['RuleId']
          new_element['text'] =element['RuleId']+" "+element['RuleName']
          new_element['value'] =element['RuleId']
          option_list.push(new_element);
        }
        this.setState({['optionlistdata']:option_list,rulesStore:ruleid_list});
      })
      .catch(function (error) {
        console.log(error);
    })
  }

  handleClear=()=>{
    //console.log('handle clear')
    this.setState({['ruleId']:'0000',
    ['accId']:''
  })
  }

  handleEdit=(selectedRuleData)=>{
    //console.log(selectedRuleData)
    this.setState({['toEdit']:'yes'});
    this.setState({['dataToEdit']:selectedRuleData});
    this.setState({['openModal']:true})
  }

  handleDelete=(selectedRuleData)=>{
    this.setState({['toDelete']:'yes'});
    this.setState({['openDeletePopUp']:true})
    this.setState({['dataToDelete']:selectedRuleData});
  }

  handleDeletePopUpClose=()=>{
    this.setState({['toDelete']:'no',openDeletePopUp: false})
  }

  handleEditPopUpClose=()=>{
    this.setState({['openModal']:false});
    this.setState({['toEdit']:'no'});
  }

  handleDeleteConfirm=async()=>{
    const selectedUrl = this.state.selectCloud === commercialCloud ? config.apiendpoint : config.govCloudApi
  //  This method does not apply for this table
    const data = {
      params:{"RuleId": parseInt(this.state.ruleId),"LastModifiedBy": this.props.authState.accessToken.claims.sub},
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // // 'Access-Control-Allow-Methods': 'POST',
      // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      headers:{
        'Authorization': this.props.authState.accessToken.accessToken
      }
    };
    await axios.delete(selectedUrl + 'configrules', data)
    .then(res => {
      if(res.data.status_code === 204){
        this.handleDeletePopUpClose();
        this.setState({
          onToastMsg:true,
          toastMessage:res.data.body.message,
          toastColour:'green',
          ruleId:'0000',
          searchStatus:''
        })
        this.readDataFromApi();
      }
      else{
        this.setState({
          onToastMsg:true,
          toastMessage:res.data.body.message,
          toastColour:'red'
        })
        // this.readDataRuleIdFromApi()
      }
    })
    .catch(function (error) {
      console.log(error);
  })
  }

  dismissToastMessage=()=>{
    this.setState({onToastMsg:false})
  }
  handleSelectedCloudType=(data)=>{
    this.setState((prevState)=>({...prevState,selectCloud:data,searchStatus:'',accId:'',ruleId:'',optionlistdata:[],acc_options:[]}),()=>{
      this.readDataFromApi();
      this.readDataAccountIdFromApi();
    })
  }

  render() {
    let searchresult;
    let editformpopup;
    let deleteformpopup;
    if (this.state.searchStatus && this.state.ruleId != '0000'){
      searchresult = <SortableTable {...this.state} handleEdit={this.handleEdit} handleDelete={this.handleDelete} headers={this.state.headers} enableDelete={true}/>
    }
    if (this.state.toEdit ==='yes'){
      editformpopup = <ConfigAccountEditFormContainer getDataForSearch={this.getDataForSearch} {...this.state} handleSubmit={this.handleSubmit} dataEditedLoading={this.dataEditedLoading} handleEditPopUpClose={this.handleEditPopUpClose} accessData={this.props}/>
    }
    if (this.state.toDelete==='yes'){
      deleteformpopup = <ConfigAccountDeleteForm {...this.state} handleDeletePopUpClose={this.handleDeletePopUpClose} handleDeleteConfirm={this.handleDeleteConfirm} accessData={this.props}/>
      // delete module goes here
    }
    return (
        <div>
          <ConfigAccountSearchComp setSelectedCloudType={this.handleSelectedCloudType} handleSubmit = {this.handleSubmit} handleDropDownChange = {this.handleDropDownChange} handleChange={this.handleChange} {...this.state} readDataFromApi={this.readDataFromApi} dismissToastMessage={this.dismissToastMessage} readDataAccountIdFromApi={this.readDataAccountIdFromApi}/>
          {searchresult}
          {editformpopup}
          {deleteformpopup}
        </div>
    )
  }
}

export default ConfigAccountSearchContainer;