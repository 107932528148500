import React, { Component } from 'react';
import { Form, Segment, Button, Modal,Header,Input,Confirm,Label, FormField, Icon, Popup} from 'semantic-ui-react';
import ReactTooltip from "react-tooltip";
import ToastMessage from "../../../../ReUsableComponent/ToastMessageComp";
import { commercialCloud, labelAws, labelAwsUsGov } from '../../../../../Config/appConstant';
// added code for taost message

const rule_enabled = [
  { key: 't', text: 'True', value: true },
  { key: 'f', text: 'False', value: false }
]

const action_enabled = [
  { key: 't', text: 'True', value: 'True' },
  { key: 'f', text: 'False', value: 'False'},
  { key: 'e', text: 'Email', value: 'Email'},
  { key: 'd', text: 'Detach', value: 'Detach'}
]


class ConfigAccountEditForm extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount(){

    this.props.setEditData();
    
  }
  
  createConfirmMsg=()=>{
    return  `Are you sure you want to update in ${this.props.selectCloud === "GOV_CLOUD" ? "GovCloud" :"Commercial Cloud"}?`
  }


  render() {
    const {
      serviceNowTicketId,
      copyServiceNowTicketId,
      copyExceptionCheckEnabled,
      exceptionCheckEnabled,
      copyActionEnabled,
      actionEnabled,
      aws_backup,
      copy_aws_backup
    } = this.props
    return (
      <Modal
      closeIcon
      open={this.props.openModal}
      onClose={()=>this.props.openOrCloseModalFunc(false)}
      // onOpen={() =>this.props.openOrCloseModalFunc(true)}
      >
      <Segment size='mini' style={{padding:'30px',borderColor:'blue',borderStyle:'Solid',margin:'6px'}}>
        {/* <Segment.Group> */}
        <Header as='h3' color='blue'>Edit Account Attributes</Header>
        {this.props.onToastMsg &&<ToastMessage toastMessage={this.props.toastMessage} colour={this.props.toastColour} 
        dismissToastMessage={this.props.dismissToastMessage}/>}
      <Form style={{width:'1300px'}}>
        <Form.Group widths='equal'>
          <Form.Input disabled fluid label='Rule ID' value={this.props.ruleId} style ={{height:'50px'}}/>
          <Form.Input disabled fluid label='Rule Name' value={this.props.ruleName} style ={{height:'50px'}}/>
          <Form.Input disabled fluid label='Account ID' value ={this.props.accId} style ={{height:'50px'}}/>
        </Form.Group>
        <Form.Group widths='equal' style={{marginTop:'25px'}}>
         <Form.Select
              label='Account Monitored'
              options={rule_enabled}
              name='exceptionCheckEnabled'
              value ={this.props.exceptionCheckEnabled}
              style={{height:'50px'}}
              onChange={this.props.handleDropDownChange}
              disabled={(this.props?.rulesStore?.find(item=>(item?.RuleId === this.props?.ruleId))?.Severity?.toLowerCase()!=="info")}
            />
            
          {/* <Form.Select
              label='Rule Enabled'
              options={rule_enabled}
              name='ruleEnabled'
              value ={this.props.ruleEnabled}
              style={{height:'50px'}}
              onChange={this.props.handleDropDownChange}
            /> */}
            <Form.Select
              label='Action Enabled'
              name='actionEnabled'
              options={action_enabled}
              value={this.props.actionEnabled}
              placeholder='False'
              style={{height:'50px'}}
              onChange={this.props.handleDropDownChange}
            />
            
             <div style={{width:"100%"}}>
             <Form.Input
              fluid
              label={(<div style={{ display: "flex", marginBottom: "5px" }} className={((copyExceptionCheckEnabled !== exceptionCheckEnabled) || (copyActionEnabled !== actionEnabled)) ? 'field' : 'field'}>
                <label>ServiceNow Ticket ID</label>  <Popup
                  content={<p style={{ fontSize: "10px" }}>
                    {(this.props?.rulesStore?.find(item=>(item?.RuleId === this.props?.ruleId))?.Severity?.toLowerCase()!=="info") ?
                     (<>Required only when <b style={{color:"red"}} >Action Enabled</b> field needs to be updated.</>)
                     :
                      (<>Required only when <b style={{color:"red"}} >Action Enabled or Account Monitored</b> fields need to be updated.</>)
                    }
                    </p>}
                  trigger={<Icon name='info circle' color='grey' style={{ marginLeft: "3px" }} size="small" />}
                  position='right center'
                /></div>)}
              error={this.props?.errors?.length > 0 ? true : false}
              placeholder="ServiceNow Ticket ID"
              value={this.props?.serviceNowTicketId}
              name="serviceNowTicketId"
              onChange={this.props.handleDropDownChange}
              style={{ height: "50px",marginTop:"-5px" }}
              readOnly={(actionEnabled===copyActionEnabled && exceptionCheckEnabled===copyExceptionCheckEnabled 
                && aws_backup === copy_aws_backup && serviceNowTicketId === copyServiceNowTicketId)}
            />
            {this.props?.errors?.length > 0 ? (<span style={{fontSize:12,color:"red"}}>{this.props?.errors[0]}</span>) : null}
             </div>
           
            
            
        </Form.Group>
        <Form.Group style={{marginTop:'25px'}} widths='equal'>

          {this.props.ruleId?.toString() === "1056" ? (
            <Form.Checkbox label="AWS Backup AMI Enabled" 
            onChange={(e,{checked})=>this.props?.handleAWSBackupAmiEnabled(checked)}
            checked={this.props?.aws_backup}
            />
          ) : null}
         
        </Form.Group>
        <Form.Group style={{marginTop:'25px'}}>
        <Confirm
          open={this.props.open}
          content = {`Are you sure you want to update in ${this.props.selectCloud === commercialCloud ? labelAws.label : labelAwsUsGov.label}?`}
          onCancel={this.props.handleCancel}
          onConfirm={this.props.handleConfirm}
          size ='big'
        />
      <Button size = 'medium' style={{backgroundColor:'#13a6d8'}} 
      onClick={this.props.handleSubmit} 
      disabled={(this.props?.errors?.length > 0 ? true : false) || 
        (actionEnabled===copyActionEnabled && exceptionCheckEnabled===copyExceptionCheckEnabled 
          && aws_backup === copy_aws_backup && serviceNowTicketId === copyServiceNowTicketId)}
      >Submit
      </Button>
      </Form.Group>
      </Form>
      {/* </Segment.Group> */}
      </Segment>
      </Modal>
    )
  }
}

export default ConfigAccountEditForm;