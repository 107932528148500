import React, { Component } from 'react';
import { Form, Segment, Confirm, Dimmer, Button, Message, Header, Loader, FormField, Radio, Select } from 'semantic-ui-react';
import ToastMessage from '../../../ReUsableComponent/ToastMessageComp';
import axios from "axios";
import config from "../../../../Config/Config";
import Modal from '../../../Modal';
import { cloudCompatibilityOption, commercialCloud, labelAws, labelAwsUsGov, localStorageCloudValue, usGovCloud } from '../../../../Config/appConstant';

class ConfigAccountAddDecomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accIdSelected: [],
      open: false,
      disabled: true,
      result: "show the modal to capture a result",
      updatedFieldValue: "",
      acc_options: [],
      accountOptions: [],
      ruleIdSelected: [],
      UpdateField: "",
      rule_options: [],
      ruleOptions: [],
      multipleAllowed: true,
      multipleAllowedRule: true,
      multipleAllowedAccount: true,
      actionEnabledOptions: [],
      toastMessage: "",
      onToastMsg: false,
      toastColour: "green",
      isLoadingAccount: false,
      isLoadingRule: false,
      isLoading: false,
      openModal:true,
      selectCloud:"",
      isConfirm:false
    };
    this.reset = this.state
    this._mounted = true
    this.readDataAccountIdFromApi = this.readDataAccountIdFromApi.bind(this)
    this.readDataFromApiForRuleName = this.readDataFromApiForRuleName.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.dismissToastMessage = this.dismissToastMessage.bind(this)
    this.onHandleClear = this.onHandleClear.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleSelectCloudSpace = this.handleSelectCloudSpace.bind(this)
  }

  onHandleClear = () => {
    this.setState((prevState)=>({...prevState,UpdateField:"",updatedFieldValue:"",ruleIdSelected:[],accIdSelected:[]}))
  }
  handleConfirm = () => {
    this.setState({ open: false, onToastMsg: false, isLoading: true , isConfirm:false});
    var UpdateValueDict = {};
    UpdateValueDict[this.state.UpdateField] = this.state.updatedFieldValue;
    let accountIdModified = [];
    if (this.state.accIdSelected?.includes("*")) {
      
      accountIdModified.push("*");
    } else {
      accountIdModified = this.state.accIdSelected;
    }
    let allRuleStore = []
    if (this.state.ruleIdSelected.includes("*")) {
      allRuleStore.push("*")
    } else {
      allRuleStore = this.state.ruleIdSelected
    }
    
    const data = {
      AccountId: accountIdModified,
      RuleId: allRuleStore,
      UpdateAttributes: UpdateValueDict,
      LastModifiedBy: this.props.authState.accessToken.claims.sub,
    };
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
        'Content-Type': 'application/json'
      },
    };
    let self = this;
    const selectedUrl = this.state.selectCloud === commercialCloud ? config["apiendpoint"] : config.govCloudApi
    axios
      .put(selectedUrl + "account/multi-update", data, options)
      .then(res => {
        if (res.data.status_code === 201) {
          if (this._mounted) {
            this.setState({
              onToastMsg: true, toastMessage: res.data.body.message,
              toastColour: "green", isLoading: false
            });
          }
        } else {
          if (this._mounted) {
            this.setState({
              onToastMsg: true, toastMessage: res.data.body.message,
              toastColour: "red", isLoading: false
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          onToastMsg: true, toastMessage: error.message,
          toastColour: "red", isLoading: false
        })
      });
  };

  handleSubmit = () => {
    this.handleConfirm()
  }

  dismissToastMessage = () => {
    this.setState({ onToastMsg: false });
  };

  readDataAccountIdFromApi = () => {
    this.setState({ isLoadingAccount: true })
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
        "Content-Type": "application/json"
      },
    };
    let self = this
    const selectedUrl = this.state.selectCloud === commercialCloud ? config["apiendpoint"] : config.govCloudApi
    axios
      .get(selectedUrl + "account/account-list", options)
      .then(res => {
        var acc_list = [{ key: "All", text: "All", value: "*" }];
        var acc_dict = res.data.body.data;
        var acc_list_created = acc_list.concat(acc_dict);
        if (this._mounted) {
          this.setState({ acc_options: acc_list_created, accountOptions: acc_list_created, isLoadingAccount: false });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (self._mounted) {
          self.setState({ isLoadingAccount: false })
        }
      });
  };
  readDataFromApiForRuleName = () => {
    this.setState({ isLoadingRule: true })
    const options = {
      headers: {
        Authorization: this.props.authState.accessToken.accessToken,
        "Content-Type": "application/json"
      },
    };
    let self = this
    const selectedUrl = this.state.selectCloud === commercialCloud ? config["apiendpoint"] : config.govCloudApi
    axios
      .get(selectedUrl + "configrules", options)
      .then(res => {
        var ruleid_list = res.data.body.data;
        let filteredRuleList = ruleid_list?.filter(item => (item.ActionAvailable === true))
        var result = filteredRuleList.filter(item => (item?.CloudCompatibility?.hasOwnProperty(this.state.selectCloud) && item?.CloudCompatibility[this.state.selectCloud]===true))
        let option_list = [];
        if(result?.length > 0){
          option_list.push({ key: "All", text: "All", value: "*" })
        }
        if (this.state.UpdateField === "ActionEnabled") {
          result?.map(item => {
            option_list.push({
              key: item.RuleId,
              text: `${item.RuleId} ${item.RuleName}`,
              value: item.RuleId
            })
          });
        } else {
          ruleid_list?.map(item => {
            option_list.push({
              key: item.RuleId,
              text: `${item.RuleId} ${item.RuleName}`,
              value: item.RuleId
            })
          });
        }

        if (this._mounted) {
          this.setState({ rule_options: option_list, ruleOptions: option_list, isLoadingRule: false });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (self._mounted) {
          self.setState({ isLoadingRule: false })
        }
      });
  };

  onChangeHandler = (event, data) => {
    const { ruleOptions, ruleIdSelected, accIdSelected, rule_options, accountOptions, acc_options } = this.state
    
    if (data.name === "UpdateField") {
      this.setState({ UpdateField: data.value }, () => {
        this.readDataFromApiForRuleName()
        this.readDataAccountIdFromApi()
      })
    }
    else if (data.name === "ruleIdSelected") {
      if (data.value.includes('*')) {
        if (acc_options.some(item => item.key === "All")) {
          let removeAllFromAccountOptions = acc_options?.filter(item => item.key !== "All")
          this.setState({ acc_options: removeAllFromAccountOptions })
        }
        this.setState({
          ruleIdSelected: data.value,
          rule_options: rule_options?.filter((item) => item.key === "All")
        })
      }
      else if (Object.keys(data.value).length === 0) {

        if (accIdSelected.length >= 2 && !accIdSelected?.includes('*')) {
          this.setState({ ruleIdSelected: data.value, rule_options: ruleOptions?.filter(item => item.key !== "All") })
        }
        else if (accIdSelected.length === 1 && !accIdSelected?.includes('*')) {
          this.setState({ ruleIdSelected: data.value, rule_options: ruleOptions })
        }
        else if (accIdSelected.includes('*')) {
          this.setState({ ruleIdSelected: data.value, rule_options: ruleOptions?.filter(item => item.key !== "All") })
        }
        else {
          this.setState({ acc_options: accountOptions, ruleIdSelected: data.value, rule_options: ruleOptions })
        }
      }
      else if (Object.keys(data.value).length >= 2) {
        let removeAllFromAccountOptions = accountOptions?.filter(item => item.key !== "All")
        if (accIdSelected?.includes("*")) {
          this.setState({ acc_options: removeAllFromAccountOptions, accIdSelected: [], ruleIdSelected: data.value, rule_options: ruleOptions?.filter((item) => item.key !== "All") })
        } else {
          this.setState({ acc_options: removeAllFromAccountOptions, ruleIdSelected: data.value, rule_options: ruleOptions?.filter((item) => item.key !== "All") })
        }

      } else if (Object.keys(data.value).length === 1) {
        if (data.value?.includes("*")) {
          let removeAllFromAccountOptions = accountOptions?.filter(item => item.key !== "All")
          this.setState({ acc_options: removeAllFromAccountOptions, ruleIdSelected: data.value, rule_options: ruleOptions })
        }
        else if (accIdSelected.length === 0 && ruleIdSelected.length === 0 && !accIdSelected?.includes("*")) {
          this.setState({ rule_options: ruleOptions.filter(item => item.key !== "All"), ruleIdSelected: data.value })
        }
        else if (accIdSelected?.includes("*")) {
          this.setState({ acc_options: accountOptions?.filter(item => item.key === "All"), ruleIdSelected: data.value, rule_options: ruleOptions?.filter((item) => item.key !== "All") })
        }
        else if (accIdSelected.length === 0 && !data.value?.includes("*")) {
          this.setState({ acc_options: accountOptions, ruleIdSelected: data.value })
        }
        else if (Object.keys(data.value).length === 1 && !accIdSelected?.includes('*')) {
          this.setState({ acc_options: accountOptions?.filter(item => item.key !== "All"), ruleIdSelected: data.value, rule_options: ruleOptions?.filter(item => item.key !== "All") })
        }
        else if (Object.keys(data.value).length === 1) {
          this.setState({ acc_options: accountOptions?.filter(item => item.key !== "All"), ruleIdSelected: data.value, rule_options: ruleOptions?.filter(item => item.key !== "All") })
        }
        else {
          this.setState({ acc_options: accountOptions, ruleIdSelected: data.value, rule_options: ruleOptions })
        }
      }
      else {
        this.setState({ ruleIdSelected: data.value, rule_options: ruleOptions })
      }
    }
    else if (data.name === "accIdSelected") {
      if (data.value.includes('*')) {
        if (rule_options.some(item => item.key === "All")) {
          let removeAllFromRuleOptions = rule_options?.filter(item => item.key !== "All")
          this.setState({ rule_options: removeAllFromRuleOptions })
        }
        this.setState({
          accIdSelected: data.value,
          acc_options: acc_options?.filter((item) => item.key === "All")
        })
      }
      else if (Object.keys(data.value).length === 0) {
        if (ruleIdSelected.length >= 2 && !ruleIdSelected?.includes('*')) {
          this.setState({ accIdSelected: data.value, acc_options: accountOptions?.filter(item => item.key !== "All") })
        }
        else if (ruleIdSelected.length === 1 && !ruleIdSelected?.includes('*')) {
          this.setState({ accIdSelected: data.value, acc_options: accountOptions })
        }
        else if (ruleIdSelected.includes('*')) {
          this.setState({ accIdSelected: data.value, acc_options: accountOptions?.filter(item => item.key !== "All") })
        }
        else {
          this.setState({ accIdSelected: data.value, acc_options: accountOptions, rule_options: ruleOptions })
        }
      }
      else if (Object.keys(data.value).length >= 2) {
        let removeAllFromRuleOptions = ruleOptions.filter(item => item.key !== "All")
        if (ruleIdSelected?.includes("*")) {
          this.setState({ rule_options: removeAllFromRuleOptions, ruleIdSelected: [], accIdSelected: data.value, acc_options: accountOptions?.filter((item) => item.key !== "All") })

        } else {
          this.setState({ rule_options: removeAllFromRuleOptions, accIdSelected: data.value, acc_options: accountOptions?.filter((item) => item.key !== "All") })

        }
      }
      else if (Object.keys(data.value).length === 1) {
        let removeAllFromRuleOptions = ruleOptions.filter(item => item.key !== "All")
        if (data.value?.includes("*")) {
          this.setState({ rule_options: removeAllFromRuleOptions, accIdSelected: data.value, acc_options: accountOptions })
        }
        else if (ruleIdSelected?.includes('*')) {
          this.setState({ rule_options: ruleOptions?.filter(item => item.key === "All"), accIdSelected: data.value, acc_options: accountOptions.filter((item) => item.key !== "All") })
        }
        else if (accIdSelected.length === 0 && ruleIdSelected.length === 0 && !ruleIdSelected?.includes("*")) {
          this.setState({ acc_options: accountOptions.filter(item => item.key !== "All"), accIdSelected: data.value })
        }
        else if (ruleIdSelected.length === 0 && !data.value?.includes("*")) {
          this.setState({ rule_options: ruleOptions, accIdSelected: data.value })
        }
        else if (Object.keys(data.value).length === 1 && !ruleIdSelected?.includes('*')) {
          this.setState({ rule_options: ruleOptions?.filter(item => item.key !== "All"), accIdSelected: data.value, acc_options: accountOptions?.filter(item => item.key !== "All") })
        }
        else if (Object.keys(data.value).length === 1) {
          this.setState({ rule_options: ruleOptions?.filter(item => item.key !== "All"), accIdSelected: data.value, acc_options: accountOptions?.filter(item => item.key !== "All") })
        }
        else {
          this.setState({ rule_options: ruleOptions, accIdSelected: data.value, acc_options: accountOptions })
        }
      }
      else {
        this.setState({ accIdSelected: data.value, acc_options: accountOptions })
      }
    }
  }
  handleSelectCloudSpace = (event, data) => {
    localStorage.setItem(localStorageCloudValue,data?.value)
    this.setState((prevState)=>({...prevState, [data.name]: data.value,
      openModal: false,
      UpdateField:'',
      updatedFieldValue:''
    }),()=>{
      this.readDataFromApiForRuleName()
      this.readDataAccountIdFromApi()
    });
  };

  handleCloseModal = ()=>{
   if(this._mounted){
    this.setState((prevState)=>({...prevState,openModal: false}),()=>{
      window.location.href = "/"
    });
    
   }
  }

  componentDidMount() {
    this._mounted = true
    let value = localStorage.getItem(localStorageCloudValue)
    if(value !== null && value !==""){
      this.handleSelectCloudSpace({},{name:"selectCloud",value:value})
    }
  }

  render() {
    const { field_available_options, forExceptionsCheckEnabled, forActionEnabled } = this.props
    const { rule_options, UpdateField, updatedFieldValue, acc_options, accIdSelected,
      ruleIdSelected, isLoadingAccount, isLoadingRule, isLoading } = this.state

    return (
      <Segment size='mini' style={{
        padding: '30px',
        borderColor: 'blue',
        borderStyle: 'Solid',
        margin: '6px'
      }}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Header as='h3' color='blue'>Bulk Updates</Header>
        <div>
          <b style={{fontSize:13,marginBottom:2}}>Selected organization:</b>
          <Select
          fluid
          label='Select Cloud'
          options={cloudCompatibilityOption}
          value={this.state.selectCloud}
          name="selectCloud"
          onChange={this.handleSelectCloudSpace}
          placeholder='Select Cloud'
          style={{position: "centre", width: "150px" }}
          />
        </div>
        </div>
        <Modal openModal={this.state.openModal} closeModal={this.handleCloseModal} title={'Choose organization:'}>
        <Form>
            <Form.Group widths='equal'>
              <Form.Field>
                <Radio
                  label={labelAws.label}
                  name="selectCloud"
                  value={labelAws.value}
                  checked={this.state.selectCloud === commercialCloud}
                  onChange={this.handleSelectCloudSpace}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={labelAwsUsGov.label}
                  name="selectCloud"
                  value={labelAwsUsGov.value}
                  checked={this.state.selectCloud === usGovCloud}
                  onChange={this.handleSelectCloudSpace}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal>
        {this.state.onToastMsg && <ToastMessage toastMessage={this.state.toastMessage} colour={this.state.toastColour} dismissToastMessage={this.dismissToastMessage} />}
        <Form style={{ width: '1300px' }}>
          <Form.Group widths='equal'>
            <Form.Select fluid data-tip data-for="registerTip" label='Field to Update'
              placeholder='Select a field'
              options={field_available_options}
              required
              name='UpdateField'
              value={UpdateField}
              onChange={(e, data) => this.onChangeHandler(e, data)}
            />
            <Form.Select
              fluid
              label='Value'
              required
              options={UpdateField === "ActionEnabled" ? forActionEnabled : forExceptionsCheckEnabled}
              name='updatedFieldValue'
              placeholder='Select a value'
              value={updatedFieldValue}
              onChange={(e, data) => {
                this.setState({ updatedFieldValue: data.value })
              }}
              disabled={UpdateField === "" ? true : false}
            />
            <Form.Select
              fluid
              label='Rule Identifier'
              required
              search
              multiple
              options={rule_options}
              name='ruleIdSelected'
              value={ruleIdSelected}
              loading={isLoadingRule}
              onChange={(e, data) => this.onChangeHandler(e, data)}
              placeholder='Select a config rule'
              disabled={UpdateField === "" ? true : (isLoadingRule ? true : false)}
            />
            <Form.Select
              label='Account Name'
              loading={isLoadingAccount}
              options={acc_options}
              search
              placeholder='Select Accounts from list'
              required
              name='accIdSelected'
              multiple
              value={accIdSelected}
              floated
              onChange={(e, data) => this.onChangeHandler(e, data)}
              disabled={UpdateField === "" ? true : (isLoadingAccount ? true : false)}
            />
          </Form.Group>
          <Dimmer active={isLoading} inverted>
            <Loader inverted content='Updating' />
          </Dimmer>
          <Button type='submit' size='large' className='add' value='Submit'
            style={{ marginTop: '27px', backgroundColor: '#13a6d8' }}
            onClick={() => this.setState((prevState)=>({...prevState,isConfirm:true}))}
            disabled={(UpdateField && updatedFieldValue!=="" && ruleIdSelected.length !== 0 && accIdSelected.length !== 0) ? false : true}>
            Update
          </Button>
          <Button type='reset' size='large'
            style={{ marginTop: '27px', backgroundColor: '#13a6d8' }}
            onClick={() => this.onHandleClear()}>Reset</Button>
        </Form>
        <Confirm
          open={this.state.isConfirm}
          onCancel={() => this.setState((prevState)=>({...prevState,isConfirm:false}))}
          onConfirm={() => this.handleSubmit()}
          content={`Are you sure you want to update in ${this.state.selectCloud === commercialCloud ? labelAws.label : labelAwsUsGov.label}?`}
        />
      </Segment>
    )
  }
  componentWillUnmount() {
    this._mounted = false
  }
}
export default ConfigAccountAddDecomForm;